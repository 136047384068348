import React from 'react';
import styled from 'styled-components';
import { imageURL } from "../../../../../Api.js";

import img1 from "../../../../../assets/Brain/Test.jpg"


const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: Helvetica, Arial, sans-serif;
  background: url(${img1}) no-repeat center center;
  background-size: cover;
  height: 100vh;
  color: white;
  padding: 20px;
  text-align: center;

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 50px;
    text-align: left;
  }
`;

const Content = styled.div`
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;

  @media (min-width: 768px) {
    max-width: 550px;
    margin: 0;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #f00d88;

  @media (min-width: 768px) {
    font-size: 2.7rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 0.8rem;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (min-width: 768px) {
    font-size: 1.3rem;
  }
`;

const StyledLink = styled.a`
  background-color: #f00d88;
  border: none;
  color: white;
  font-weight: bold;
  padding: 10px 10px;
  margin-top: 20px;
  font-size: 1rem;
  cursor: pointer;
  border-radius: 25px;
  text-transform: uppercase;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  text-decoration: none;
  display: inline-block;
  text-align: center;

  &:hover {
    background-color: #e60073;
  }

  @media (min-width: 768px) {
    font-size: 1.2rem;
  }
`;

const App = () => {
  return (
    <Container>
      <Content>
        <Title>Tia TestHub</Title>
        <Subtitle>
          TIA Test Hub provides extensive resources and practice exams for competitive tests like JEE, NEET, and Olympiads, allowing students to simulate exam conditions, assess their knowledge, and improve their performance with instant feedback.
        </Subtitle>
        <StyledLink href="https://testhub.tiaedu.in/" target="_blank" rel="noopener noreferrer">
          Join Now
        </StyledLink>
      </Content>
    </Container>
  );
};

export default App;
