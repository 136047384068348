import React from 'react';
import styled from 'styled-components';
import { imageURL } from "../../../../../Api.js";
import { FaYoutube } from 'react-icons/fa';  // Importing the YouTube icon from react-icons

import img1 from "../../../../../assets/Brain/Brain1.jpg"

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: url(${img1}) no-repeat center center;
  background-size: cover;
  height: 100vh;
  color: white;
  padding: 20px;
  text-align: center;
  position: relative;

  @media (min-width: 768px) {
    justify-content: flex-start;
    padding: 50px;
    text-align: left;
  }
`;

const Content = styled.div`
  max-width: 90%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;

  @media (min-width: 768px) {
    max-width: 550px;
    margin: 0;
  }
`;

const Title = styled.h1`
  font-size: 2rem;
  margin-bottom: 20px;
  color: #f00d88;

  @media (min-width: 768px) {
    font-size: 2.7rem;
  }

  @media (min-width: 1024px) {
    font-size: 3rem;
  }
`;

const Subtitle = styled.p`
  font-size: 0.8rem;
  margin-bottom: 30px;
  
  @media (max-width: 768px) {
    font-size: 1.1rem;
  }

  @media (min-width: 768px) {
    font-size: 1.3rem;
  }
`;

const YoutubeSection = styled.div`
  position: absolute;
  bottom: 20px;
  left: 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StudentChannels = styled.p`
  font-size: 1.1rem;
  margin-bottom: 10px;
  color: #ffffff;
  font-weight: bold;
`;

const VisitLink = styled.div`
  display: flex;
  align-items: center;
  font-size: 1rem;
  color: #ffffff;
  
  a {
    color: #ff0000;
    text-decoration: none;
    margin-left: 5px;
  }

  a:hover {
    text-decoration: underline;
  }
`;

const App = () => {
  return (
    <Container>
      <Content>
        <Title>Tia Brain</Title>
        <Subtitle>The TIA BRAIN program enhances children's concentration, discipline, and memory, bringing out hidden talents and improving behavior, academics, and physical health, leading to noticeable positive changes in studies and daily activities.</Subtitle>

        <YoutubeSection>
          <StudentChannels>Parent Testimonials</StudentChannels>
          <VisitLink>
            <FaYoutube size={30} color="#ff0000" />
            <a href="https://www.youtube.com/@tia2013"   target="_blank" rel="noopener noreferrer">Visit</a>
          </VisitLink>
        </YoutubeSection>
      </Content>
    </Container>
  );
};

export default App;
