import React, { useState } from 'react';
import styled from 'styled-components';
import { imageURL } from "../../../../../../Api.js";

import image1 from "../../../../../../assets/photo/IMG-20241029-WA0001.jpg";
import image2 from "../../../../../../assets/photo/IMG-20241029-WA0002.jpg";
import image3 from "../../../../../../assets/photo/IMG-20241029-WA0003.jpg";
import image4 from "../../../../../../assets/photo/IMG-20241029-WA0004.jpg";
import image5 from "../../../../../../assets/photo/IMG-20241029-WA0005.jpg";
import image6 from "../../../../../../assets/photo/IMG-20241029-WA0006.jpg";
import image7 from "../../../../../../assets/photo/IMG-20241029-WA0007.jpg";
import image8 from "../../../../../../assets/photo/IMG-20241029-WA0008.jpg";
import image9 from "../../../../../../assets/photo/IMG-20241029-WA0009.jpg";
import image10 from "../../../../../../assets/photo/IMG-20241029-WA0010.jpg";
import image11 from "../../../../../../assets/photo/IMG-20241029-WA0011.jpg";
import image12 from "../../../../../../assets/photo/IMG-20241029-WA0012.jpg";
import image13 from "../../../../../../assets/photo/IMG-20241029-WA0013.jpg";
import image14 from "../../../../../../assets/photo/IMG-20241029-WA0014.jpg";
import image15 from "../../../../../../assets/photo/IMG-20241029-WA0015.jpg";
import image16 from "../../../../../../assets/photo/IMG-20241029-WA0016.jpg";
import image17 from "../../../../../../assets/photo/IMG-20241029-WA0017.jpg";
import image18 from "../../../../../../assets/photo/IMG-20241029-WA0018.jpg";
import image19 from "../../../../../../assets/photo/IMG-20241029-WA0019.jpg";
import image20 from "../../../../../../assets/photo/IMG-20241029-WA0020.jpg";
import image21 from "../../../../../../assets/photo/IMG-20241029-WA0022.jpg";
import image22 from "../../../../../../assets/photo/IMG-20241029-WA0022.jpg";
import image23 from "../../../../../../assets/photo/IMG-20241029-WA0023.jpg";
import image24 from "../../../../../../assets/photo/IMG-20241029-WA0024.jpg";
import image25 from "../../../../../../assets/photo/IMG-20241029-WA0025.jpg";
import image26 from "../../../../../../assets/photo/IMG-20241029-WA0026.jpg";
import image27 from "../../../../../../assets/photo/IMG-20241029-WA0027.jpg";
import image28 from "../../../../../../assets/photo/IMG-20241029-WA0028.jpg";
import image29 from "../../../../../../assets/photo/IMG-20241029-WA0029.jpg";
import image30 from "../../../../../../assets/photo/IMG-20241029-WA0030.jpg";
import image31 from "../../../../../../assets/photo/IMG-20241029-WA0032.jpg";
import image32 from "../../../../../../assets/photo/IMG-20241029-WA0032.jpg";
import image33 from "../../../../../../assets/photo/IMG-20241029-WA0033.jpg";
import image34 from "../../../../../../assets/photo/IMG-20241029-WA0034.jpg";
import image35 from "../../../../../../assets/photo/IMG-20241029-WA0035.jpg";
import image36 from "../../../../../../assets/photo/IMG-20241029-WA0036.jpg";
import image37 from "../../../../../../assets/photo/IMG-20241029-WA0037.jpg";
import image38 from "../../../../../../assets/photo/IMG-20241029-WA0038.jpg";
import image39 from "../../../../../../assets/photo/IMG-20241029-WA0039.jpg";
import image40 from "../../../../../../assets/photo/IMG-20241029-WA0040.jpg";
import image41 from "../../../../../../assets/photo/IMG-20241029-WA0041.jpg";
import image42 from "../../../../../../assets/photo/IMG-20241029-WA0042.jpg";
import image43 from "../../../../../../assets/photo/IMG-20241029-WA0043.jpg";
import image44 from "../../../../../../assets/photo/IMG-20241029-WA0044.jpg";
import image45 from "../../../../../../assets/photo/IMG-20241029-WA0045.jpg";
import image46 from "../../../../../../assets/photo/IMG-20241029-WA0046.jpg";
import image47 from "../../../../../../assets/photo/IMG-20241029-WA0047.jpg";
import image48 from "../../../../../../assets/photo/IMG-20241029-WA0048.jpg";
import image49 from "../../../../../../assets/photo/IMG-20241029-WA0049.jpg";
import image50 from "../../../../../../assets/photo/IMG-20241029-WA0050.jpg";
import image51 from "../../../../../../assets/photo/IMG-20241029-WA0051.jpg";
import image52 from "../../../../../../assets/photo/IMG-20241029-WA0052.jpg";
import image53 from "../../../../../../assets/photo/IMG-20241029-WA0053.jpg";
import image54 from "../../../../../../assets/photo/IMG-20241029-WA0054.jpg";
import image55 from "../../../../../../assets/photo/IMG-20241029-WA0055.jpg";
import image56 from "../../../../../../assets/photo/IMG-20241029-WA0056.jpg";
import image57 from "../../../../../../assets/photo/IMG-20241029-WA0057.jpg";
import image58 from "../../../../../../assets/photo/IMG-20241029-WA0058.jpg";
import image59 from "../../../../../../assets/photo/IMG-20241029-WA0059.jpg";
import image60 from "../../../../../../assets/photo/IMG-20241029-WA0060.jpg";
import image61 from "../../../../../../assets/photo/IMG-20241029-WA0061.jpg";
import image62 from "../../../../../../assets/photo/IMG-20241029-WA0062.jpg";
import image63 from "../../../../../../assets/photo/IMG-20241029-WA0063.jpg";
import image64 from "../../../../../../assets/photo/IMG-20241029-WA0064.jpg";
import image65 from "../../../../../../assets/photo/IMG-20241029-WA0065.jpg";
import image66 from "../../../../../../assets/photo/IMG-20241029-WA0066.jpg";
import image67 from "../../../../../../assets/photo/IMG-20241029-WA0067.jpg";
import image68 from "../../../../../../assets/photo/IMG-20241029-WA0068.jpg";
import image69 from "../../../../../../assets/photo/IMG-20241029-WA0069.jpg";
import image70 from "../../../../../../assets/photo/IMG-20241029-WA0070.jpg";
import image71 from "../../../../../../assets/photo/IMG-20241029-WA0071.jpg";
import image72 from "../../../../../../assets/photo/IMG-20241029-WA0072.jpg";
import image73 from "../../../../../../assets/photo/IMG-20241029-WA0073.jpg";
import image74 from "../../../../../../assets/photo/IMG-20241029-WA0074.jpg";
import image75 from "../../../../../../assets/photo/IMG-20241029-WA0075.jpg";
import image76 from "../../../../../../assets/photo/IMG-20241029-WA0076.jpg";
import image77 from "../../../../../../assets/photo/IMG-20241029-WA0077.jpg";
import image78 from "../../../../../../assets/photo/IMG-20241029-WA0078.jpg";
import image79 from "../../../../../../assets/photo/IMG-20241029-WA0079.jpg";
import image80 from "../../../../../../assets/photo/IMG-20241029-WA0080.jpg";
import image81 from "../../../../../../assets/photo/IMG-20241029-WA0080.jpg";
import image82 from "../../../../../../assets/photo/IMG-20241029-WA0083.jpg";
import image83 from "../../../../../../assets/photo/IMG-20241029-WA0083.jpg";
import image84 from "../../../../../../assets/photo/IMG-20241029-WA0084.jpg";
import image85 from "../../../../../../assets/photo/IMG-20241029-WA0085.jpg";
import image86 from "../../../../../../assets/photo/IMG-20241029-WA0086.jpg";
import image87 from "../../../../../../assets/photo/IMG-20241029-WA0087.jpg";
import image88 from "../../../../../../assets/photo/IMG-20241029-WA0089.jpg";
import image89 from "../../../../../../assets/photo/IMG-20241029-WA0089.jpg";
import image90 from "../../../../../../assets/photo/IMG-20241029-WA0090.jpg";
import image91 from "../../../../../../assets/photo/IMG-20241029-WA0091.jpg";
import image92 from "../../../../../../assets/photo/IMG-20241029-WA0092.jpg";
import image93 from "../../../../../../assets/photo/IMG-20241029-WA0093.jpg";
import image94 from "../../../../../../assets/photo/IMG-20241029-WA0094.jpg";
import image95 from "../../../../../../assets/photo/IMG-20241029-WA0099.jpg";
import image96 from "../../../../../../assets/photo/IMG-20241029-WA0099.jpg";
import image97 from "../../../../../../assets/photo/IMG-20241029-WA0097.jpg";
import image98 from "../../../../../../assets/photo/IMG-20241029-WA0099.jpg";
import image99 from "../../../../../../assets/photo/IMG-20241029-WA0099.jpg";
import image100 from "../../../../../../assets/photo/IMG-20241029-WA0100.jpg";
import image101 from "../../../../../../assets/photo/IMG-20241029-WA0101.jpg";
import image102 from "../../../../../../assets/photo/IMG-20241029-WA0102.jpg";
import image103 from "../../../../../../assets/photo/IMG-20241029-WA0103.jpg";
import image104 from "../../../../../../assets/photo/IMG-20241029-WA0104.jpg";
import image105 from "../../../../../../assets/photo/IMG-20241029-WA0105.jpg";
import image106 from "../../../../../../assets/photo/IMG-20241029-WA0106.jpg";
import image107 from "../../../../../../assets/photo/IMG-20241029-WA0107.jpg";
import image108 from "../../../../../../assets/photo/IMG-20241029-WA0108.jpg";
import image109 from "../../../../../../assets/photo/IMG-20241029-WA0109.jpg";
import image110 from "../../../../../../assets/photo/IMG-20241029-WA0110.jpg";
import image111 from "../../../../../../assets/photo/IMG-20241029-WA0111.jpg";
import image112 from "../../../../../../assets/photo/IMG-20241029-WA0112.jpg";
import image113 from "../../../../../../assets/photo/IMG-20241029-WA0113.jpg";
import image114 from "../../../../../../assets/photo/IMG-20241029-WA0114.jpg";
import image115 from "../../../../../../assets/photo/IMG-20241029-WA0115.jpg";
import image116 from "../../../../../../assets/photo/IMG-20241029-WA0116.jpg";
import image117 from "../../../../../../assets/photo/IMG-20241029-WA0117.jpg";
import image118 from "../../../../../../assets/photo/IMG-20241029-WA0118.jpg";
import image119 from "../../../../../../assets/photo/IMG-20241029-WA0119.jpg";
import image120 from "../../../../../../assets/photo/IMG-20241029-WA0120.jpg";
import image121 from "../../../../../../assets/photo/IMG-20241029-WA0121.jpg";
import image122 from "../../../../../../assets/photo/IMG-20241029-WA0122.jpg";
import image123 from "../../../../../../assets/photo/IMG-20241029-WA0123.jpg";
import image124 from "../../../../../../assets/photo/IMG-20241029-WA0124.jpg";
import image125 from "../../../../../../assets/photo/IMG-20241029-WA0125.jpg";
import image126 from "../../../../../../assets/photo/IMG-20241029-WA0126.jpg";
import image127 from "../../../../../../assets/photo/IMG-20241029-WA0127.jpg";
import image128 from "../../../../../../assets/photo/IMG-20241029-WA0128.jpg";
import image129 from "../../../../../../assets/photo/IMG-20241029-WA0129.jpg";
import image130 from "../../../../../../assets/photo/IMG-20241029-WA0130.jpg";
import image131 from "../../../../../../assets/photo/IMG-20241029-WA0131.jpg";
import image132 from "../../../../../../assets/photo/IMG-20241029-WA0132.jpg";
import image133 from "../../../../../../assets/photo/IMG-20241029-WA0133.jpg";
import image134 from "../../../../../../assets/photo/IMG-20241029-WA0134.jpg";
import image135 from "../../../../../../assets/photo/IMG-20241029-WA0135.jpg";
import image136 from "../../../../../../assets/photo/IMG-20241029-WA0136.jpg";
import image137 from "../../../../../../assets/photo/IMG-20241029-WA0137.jpg";
import image138 from "../../../../../../assets/photo/IMG-20241029-WA0138.jpg";
import image139 from "../../../../../../assets/photo/IMG-20241029-WA0139.jpg";
import image140 from "../../../../../../assets/photo/IMG-20241029-WA0140.jpg";
import image141 from "../../../../../../assets/photo/IMG-20241029-WA0141.jpg";
import image142 from "../../../../../../assets/photo/IMG-20241029-WA0142.jpg";
import image143 from "../../../../../../assets/photo/IMG-20241029-WA0143.jpg";
import image144 from "../../../../../../assets/photo/IMG-20241029-WA0144.jpg";
import image145 from "../../../../../../assets/photo/IMG-20241029-WA0145.jpg";
import image146 from "../../../../../../assets/photo/IMG-20241029-WA0146.jpg";
import image147 from "../../../../../../assets/photo/IMG-20241029-WA0147.jpg";
import image148 from "../../../../../../assets/photo/IMG-20241029-WA0148.jpg";
import image149 from "../../../../../../assets/photo/IMG-20241029-WA0149.jpg";
import image150 from "../../../../../../assets/photo/IMG-20241029-WA0150.jpg";
import image151 from "../../../../../../assets/photo/IMG-20241029-WA0150.jpg";
import image152 from "../../../../../../assets/photo/IMG-20241029-WA0152.jpg";
import image153 from "../../../../../../assets/photo/IMG-20241029-WA0153.jpg";
import image154 from "../../../../../../assets/photo/IMG-20241029-WA0154.jpg";
import image155 from "../../../../../../assets/photo/IMG-20241029-WA0155.jpg";
import image156 from "../../../../../../assets/photo/IMG-20241029-WA0156.jpg";
import image157 from "../../../../../../assets/photo/IMG-20241029-WA0157.jpg";
import image158 from "../../../../../../assets/photo/IMG-20241029-WA0155.jpg";
import image159 from "../../../../../../assets/photo/IMG-20241029-WA0160.jpg";
import image160 from "../../../../../../assets/photo/IMG-20241029-WA0160.jpg";

const GalleryContainer = styled.div`
  text-align: center;
  padding: 20px;
`;

const Heading = styled.h2`
  font-size: 2em;
  margin-bottom: 10px;
  @media (max-width: 768px) {
    font-size: 1.5em;
  }
`;

const Paragraph = styled.p`
  margin-bottom: 20px;
  color: #666;
`;

const Categories = styled.div`
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
  font-size: 13px;

  @media (max-width: 768px) {
    align-items: center;
    gap: 10px;
  }
`;

const CategoryButton = styled.button`
  padding: 10px 15px;
  border: none;
  background-color: #ffffff;
  cursor: pointer;
  position: relative;
  width: 120px;
  height: 45px;
  font-size: 16px;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 0;
    height: 2px;
    background: linear-gradient(90deg, #f16dcb, #f16dcb);
    transition: width 0.3s ease;
  }
  &.active::after {
    width: 100%;
  }

  @media (max-width: 1200px) {
    width: 100px;
    height: 40px;
    font-size: 14px;
  }

  @media (max-width: 768px) {
    width: auto;
    height: auto;
    padding: 10px;
    font-size: 16px;
  }

  @media (max-width: 480px) {
    font-size: 14px;
  }

  @media (max-width: 320px) {
    font-size: 12px;
  }
`;

const GalleryGrid = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const GalleryRow = styled.div`
  display: flex;
  gap: 20px;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const GalleryColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  flex: ${props => props.large ? 2 : 1};
`;

const GalleryItem = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 8px;

  img {
    width: 100%;
    height: auto;
    display: block;
    border-radius: 8px;
    height: ${props => (props.large ? '400px' : '190px')};
    transition: transform 0.3s ease;
    @media (max-width: 768px) {
      height: auto;
    }
  }

  &:hover img {
    transform: scale(1.05); // Zoom effect on hover
  }

  &:hover::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-radius: 8px;
  }
`;


const VideoSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const VideoRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;

 
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const VideoColumn = styled.div`
  flex: 1 1 48%;

  @media (max-width: 768px) {
    flex: 1 1 100%;
  }
`;

const VideoItem = styled.div`
  width: 100%;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;
  overflow: hidden;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
  }
`;

const LoadMoreButton = styled.button`
  padding: 10px 15px;
  background: linear-gradient(90deg, #f16dcb, #f16dcb);
  border: none;
  border-radius: 15px;
  margin: 20px auto;
  color: white;
  cursor: pointer;
  padding: 10px 20px;
  transition: background 0.3s ease;
  &:hover {
    background: linear-gradient(90deg, #ed39ba, #ed39ba);
  }
  @media (max-width: 1200px) {
    padding: 10px 18px;
    font-size: 14px;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    font-size: 12px;
  }
  @media (max-width: 480px) {
    padding: 6px 14px;
    font-size: 10px;
  }
  @media (max-width: 320px) {
    padding: 4px 12px;
    font-size: 8px;
  }
`;

const allImages = [
  image1, image2, image3, image4, image5, image7, image8, image9,
  image10, image11, image12, image13, image14, image15, image16, image17,
  image18, image19, image20, image21, image22, image23, image24, image25,
  image26, image27, image28, image29, image30, image31, image32, image33, image34, image35, 
  image36, image37, image38, image39, image40, image41, image42, image43, image44,
  image45, image46, image47, image48, image49, image50, image51, image52, image53,
  image54, image55, image56, image57, image58, image59, image60, image61, image62,
  image63, image64, image65, image66, image67, image68, image69, image70, image71,
  image72, image73, image74, image75, image76, image77, image78, image79, image80,
  image81, image82, image83, image84, image85, image86, image87, image88, image89,
  image90, image91, image92, image93, image94, image95, image96, image97, image98,
  image99, image100,
  image101, image102, image103, image104, image105, image106, image107, image108, image109,
  image110, image111, image112, image113, image114, image115, image116, image117, image118,
  image119, image120, image121, image122, image123, image124, image125, image126, image127,
  image128, image129, image130, image131, image132, image133, image134, image135, image136,
  image137, image138, image139, image140, image141, image142, image143, image144, image145,
  image146, image147, image148, image149, image150, image151, image152, image153, image154,
  image155, image156, image157, image158, image159, image160
];


const allVideos = [
  { src: "https://youtube.com/embed/_gqQiByItxc?list=PLX-Fsvk5Eo-Azx8yKHa3Htg8HnqbYfAdU" },
  { src: "https://youtube.com/embed/5LMls1knA9Q?list=PLX-Fsvk5Eo-AALUCRZ0ewQt8qTempnI4y" },
  { src: "https://youtube.com/embed/ZF2Cwmxmupo?list=PLX-Fsvk5Eo-AOQzT4VdcsnrA8HGGucXoC" },
  { src: "https://youtube.com/embed/742EVOHhZ3M?list=PLX-Fsvk5Eo-DmDQbNwkTqnB9TiBB4k2Rg" },
  { src: "https://youtube.com/embed/kXANweJGvcE?list=PLX-Fsvk5Eo-Bwr2YuVhsKqz18CR0-ZDy-" },
  { src: "https://youtube.com/embed/o4hcgISUbeg?list=PLX-Fsvk5Eo-CY23xVgDYqpZBjXiZC0-ge" },
  { src: "https://youtube.com/embed/X6XQJy3AEpg?list=PLX-Fsvk5Eo-CH1_5sKU9ZxPeQfv4OhDCu" },
  { src: "https://youtube.com/embed/M-1UOoPzUCg?list=PLX-Fsvk5Eo-CdtkBqg3zZQdAFGEL9ah8Q" },
  { src: "https://youtube.com/embed/F6qCqrJvv4E?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/_uCjVUw_o5I?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/_uCjVUw_o5I?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/wCCqpIm4NMQ?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/tc8SQJx-Iq0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/wFl0GpCLHD0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/6cfIl4Jhz4Q?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/rKRdunHmT2I?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/bthG7j9haJU?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/nxT4DAHV-CE" },
  { src: "https://youtube.com/embed/nxT4DAHV-CE?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/EId8NptPUfU?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/7qKUeldK43A?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/L7rBwbyBhEA?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/W0kIy1rVA2k?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/XE1G9cO2spc?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/Vfyhe-cgGWA?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/vR9inP3d5Ms?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/YfHU5xx9TSY?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/ox7cpE_V2yI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/Uj-VXUt7lkI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/_LCMZCmUk1E?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/kfPhH0c6ago?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/VEF95-EgJgE?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/5gl-xaPy0oc?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/cvpT235AItU?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/Ch54-crdIhQ?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/1wtot31PcCY?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/nRNSPOXMTO0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/VvsrfCpDYGk?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/VN7lpG5Yx5c?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/8ITdt0jdwBI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },

  { src: "https://youtube.com/embed/UL9Diif6RwE?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/S975tEnuTag?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/jVl-4qd4C6k" },
  { src: "https://youtube.com/embed/5Eki58IooCk" },
  { src: "https://youtube.com/embed/xP0iUxARiH0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/9Si3gC85LoM?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/0Sk578RrkTc?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/qAbEnuH1pw0?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/1GSjPdyx29c?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/NIEkq2oCfMI?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" },
  { src: "https://youtube.com/embed/rlEnR3w_DM4?list=PLX-Fsvk5Eo-Dtkuj2kW3YGpdhxac9SWKQ" }
];


const getNextImageSet = (currentIndex, setSize) => {
  const endIndex = currentIndex + setSize;
  return allImages.slice(currentIndex, endIndex);
};

const getNextVideoSet = (currentIndex, setSize) => {
  const endIndex = currentIndex + setSize;
  return allVideos.slice(currentIndex, endIndex);
};

const Gallery = () => {
  const [currentSection, setCurrentSection] = useState('images');
  const [imageIndex, setImageIndex] = useState(0);
  const [loadedImageSets, setLoadedImageSets] = useState([
    getNextImageSet(0, 5),
    getNextImageSet(5, 5)
  ]);
  const [videoIndex, setVideoIndex] = useState(0);
  const [loadedVideoSets, setLoadedVideoSets] = useState(getNextVideoSet(0, 12));

  const handleImageClick = () => {
    setCurrentSection('images');
  };

  const handleVideoClick = () => {
    setCurrentSection('videos');
  };

  const handleLoadMoreImages = () => {
    const newSet = getNextImageSet(imageIndex + loadedImageSets.length * 5, 5);
    if (newSet.length > 0) {
      setLoadedImageSets(prevSets => [...prevSets, newSet]);
    }
  };

  const handleLoadMoreVideos = () => {
    const newSet = getNextVideoSet(videoIndex + loadedVideoSets.length, 3);
    if (newSet.length > 0) {
      setLoadedVideoSets(prevSets => [...prevSets, ...newSet]);
    }
  };

  return (
    <GalleryContainer>
      <Heading>Our Gallery</Heading>
 
      <Categories>
        <CategoryButton onClick={handleImageClick} className={currentSection === 'images' ? 'active' : ''}>Images</CategoryButton>
        <CategoryButton onClick={handleVideoClick} className={currentSection === 'videos' ? 'active' : ''}>Videos</CategoryButton>
      </Categories>
      {currentSection === 'images' && (
    <GalleryGrid>
      {loadedImageSets.map((imageSet, setIndex) => (
        <GalleryRow key={`set-${setIndex}`}>
          {imageSet.map((image, index) => (
            <GalleryColumn key={`image-${setIndex}-${index}`}>
              <GalleryItem>
                <img src={image} alt={`Image ${index + 1} of set ${setIndex}`} />
              </GalleryItem>
            </GalleryColumn>
          ))}
        </GalleryRow>
      ))}
    </GalleryGrid>
  )}
      {currentSection === 'videos' && (
        <VideoSection>
          {loadedVideoSets.map((video, index) => (
            index % 3 === 0 && (
              <VideoRow key={`video-row-${Math.floor(index / 3)}`}>
                <VideoColumn>
                  <VideoItem>
                    <iframe src={video.src} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                  </VideoItem>
                </VideoColumn>
                {loadedVideoSets[index + 1] && (
                  <VideoColumn>
                    <VideoItem>
                      <iframe src={loadedVideoSets[index + 1].src} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                    </VideoItem>
                  </VideoColumn>
                )}
                {loadedVideoSets[index + 2] && (
                  <VideoColumn>
                    <VideoItem>
                      <iframe src={loadedVideoSets[index + 2].src} title="YouTube video player" frameBorder="0" allowFullScreen></iframe>
                    </VideoItem>
                  </VideoColumn>
                )}
              </VideoRow>
            )
          ))}
        </VideoSection>
      )}
      {currentSection === 'images' && loadedImageSets.flat().length < allImages.length && (
        <LoadMoreButton onClick={handleLoadMoreImages}>Load More Images →</LoadMoreButton>
      )}
      {currentSection === 'videos' && loadedVideoSets.length < allVideos.length && (
        <LoadMoreButton onClick={handleLoadMoreVideos}>Load More Videos →</LoadMoreButton>
      )}
    </GalleryContainer>
  );
};

export default Gallery;
