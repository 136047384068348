import React, { useEffect, useRef, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import { imageURL } from "../../../../../Api.js";

import image1 from "../../../../../assets/mainpage/Tiatesthubm.jpeg";


// Keyframe animation for fading in from the left
const fadeInFromLeft = keyframes`
  from {
    opacity: 0;
    transform: translateX(-50px);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
`;

// Keyframe animation for fading in from the position
const fadeInFromPosition = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

// Container styled component
const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  height: 100vh;
  background-color:#fff;

  @media (max-width: 768px) {
    height: auto;
  }
`;

// Row styled component
const Row = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;

// Content styled component
const Content = styled.div`
  flex: 1;
  padding: 20px;
  text-align: center;
  color: white;

  @media (min-width: 768px) {
    font-size: 5rem;
    max-width: 700px;
    margin-left: 80px;
    text-align: left;
  }

  @media (max-width: 768px) {
    font-size: 39px;
  }

  @media (min-width: 1600px) {
    margin-left: 200px;
  }

  @media (min-width: 1850px) {
    margin-left: 260px;
  }

  h1, p, button {
    opacity: 0;
    transition: opacity 2.5s;
  }

  h1 {
    font-size: 5rem;
    max-width: 700px;
    animation: ${props => (props.isVisible ? fadeInFromLeft : 'none')} 1.2s ease-in-out forwards;
    color:#000;

    @media (max-width:772px){
    font-size:39px;
    }
  }

  p {
    font-size: 18px;
    margin-top: 20px;
    color: #212529;
    animation: ${props => (props.isVisible ? fadeInFromLeft : 'none')} 1.2s ease-in-out forwards;

    @media (min-width: 770px) {
      margin-bottom: -40px;
    }

    @media (max-width: 768px) {
      font-size: 16px;
    }
  }

  a {
    display: inline-block;
    text-decoration: none;
  }
`;

// Image styled component
const Image = styled.img`
  width: 90%;
  max-width: 550px;
  height: auto;
  margin-top: 20px;
  margin-right: 100px;
  border-radius: 10px;
  animation: ${props => (props.isVisible ? fadeInFromPosition : 'none')} 1.2s ease-in-out forwards;

  @media (min-width: 768px) {
    width: 43%;
    height: 400px;
    margin-top: 0;
    margin-right: 100px;
  }

  @media (max-width: 768px) {
    margin-left: 100px;
  }

  @media (min-width: 1700px) {
    margin-right: 200px;
  }

  @media (min-width: 1850px) {
    margin-right: 260px;
  }
`;

// KnowMoreButton styled component
const KnowMoreButton = styled.button`
  background-color: #333;
  color: white;
  border: none;
  font-size: 15px;
  cursor: pointer;
  border-radius: 20px;
  width: 120px;
  height: 38px;
  animation: ${props => (props.isVisible ? fadeInFromLeft : 'none')} 1.2s ease-in-out forwards;

  @media (min-width: 768px) {
    margin-top: -120px;
    margin-left: 0px;
    margin-right: 0px;
  }

  @media (max-width: 768px) {
    font-size: 11px;
    width: 90px;
    height: 30px;
    padding: 1px 8px;
  }

  &:hover {
    background-color: #f14bb4;;
  }
`;

// Section5 component
const Section5 = () => {
  const imageRef = useRef(null);
  const titleRef = useRef(null);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setIsVisible(true); // Trigger animations
          }
        });
      },
      { threshold: 0.1 }
    );

    if (imageRef.current) {
      observer.observe(imageRef.current);
    }

    if (titleRef.current) {
      observer.observe(titleRef.current);
    }

    return () => {
      if (imageRef.current) {
        observer.unobserve(imageRef.current);
      }

      if (titleRef.current) {
        observer.unobserve(titleRef.current);
      }
    };
  }, []);

  return (
    <Container>
      <Row>
        <Content isVisible={isVisible}>
          <h1 ref={titleRef}>TIA TEST HUB</h1>
          <p>
            TIA TEST HUB provides comprehensive exam practice with past papers for JEE, NEET, and more, while TIA Live Tutor offers personalized online tuition to improve student focus and academic performance.
          </p>
          <a href="/tiatest">
            <KnowMoreButton isVisible={isVisible}>Know More</KnowMoreButton>
          </a>
        </Content>
        <Image
          ref={imageRef}
          src={image1}
          alt="Product"
          isVisible={isVisible}
        />
      </Row>
    </Container>
  );
};

export default Section5;
